exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about_us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-author-sanity-author-slug-current-tsx": () => import("./../../../src/pages/author/{SanityAuthor.slug__current}.tsx" /* webpackChunkName: "component---src-pages-author-sanity-author-slug-current-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-sanity-blog-article-slug-current-tsx": () => import("./../../../src/pages/blog/{SanityBlogArticle.slug__current}.tsx" /* webpackChunkName: "component---src-pages-blog-sanity-blog-article-slug-current-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact_us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-sanity-industries-slug-current-tsx": () => import("./../../../src/pages/{SanityIndustries.slug__current}.tsx" /* webpackChunkName: "component---src-pages-sanity-industries-slug-current-tsx" */),
  "component---src-pages-sanity-new-industries-slug-current-tsx": () => import("./../../../src/pages/{SanityNewIndustries.slug__current}.tsx" /* webpackChunkName: "component---src-pages-sanity-new-industries-slug-current-tsx" */),
  "component---src-pages-sanity-services-slug-current-tsx": () => import("./../../../src/pages/{SanityServices.slug__current}.tsx" /* webpackChunkName: "component---src-pages-sanity-services-slug-current-tsx" */),
  "component---src-pages-support-ukraine-tsx": () => import("./../../../src/pages/support_ukraine.tsx" /* webpackChunkName: "component---src-pages-support-ukraine-tsx" */),
  "component---src-pages-works-index-tsx": () => import("./../../../src/pages/works/index.tsx" /* webpackChunkName: "component---src-pages-works-index-tsx" */),
  "component---src-pages-works-sanity-case-study-slug-current-tsx": () => import("./../../../src/pages/works/{SanityCaseStudy.slug__current}.tsx" /* webpackChunkName: "component---src-pages-works-sanity-case-study-slug-current-tsx" */)
}

